import React, { useCallback, useEffect, useRef, useState } from "react"
import { FormValidation } from "@components/utility/formValidation"
import { Api } from "@components/utility/Api"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Form = () => {
  const [getFields, setFields] = useState(() => [])
  const [getBtnDisabled, setBtnDisabled] = useState(false)
  const zipRef = useRef()

  const handleOnKeyDown = (e) => {
    if (e.target === zipRef.current) {
      if (/\b[a-zA-Z]\b/.test(e.key)) {
        e.preventDefault()
      }
    }
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault()
    }
  }
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChange = (e) => {
    let fields = getFields
    const how_long_xywav = document.getElementById("how-long-xywav-section")
    const how_long_xyrem = document.getElementById("how-long-xyrem-section")
    const mentor_program_xywav = document.getElementById("xywav-mentor-section")

    fields[e.target.name] =
      e.target.type === "checkbox" &&
      e.target.name !== "currentMeds" &&
      e.target.name !== "currentMedsB"
        ? e.target.checked
        : e.target.value

    if (e.target.name === "currentMeds" || e.target.name === "currentMedsB") {
      const xywav_ck = document.getElementById("currentXywav")
      const xyrem_ck = document.getElementById("currentXyrem")
      const none_ck = document.getElementById("currentNone")
      const other_ck = document.getElementById("currentOther")

      if (e.target.id !== "currentOther") {
        xywav_ck.checked =
          e.target.id !== "currentXywav" ? false : xywav_ck.checked
        xyrem_ck.checked =
          e.target.id !== "currentXyrem" ? false : xyrem_ck.checked
      } else {
        none_ck.checked =
          e.target.id !== "currentNone" ? false : none_ck.checked
        other_ck.checked =
          e.target.id !== "currentOther" ? false : other_ck.checked
      }

      if (e.target.id === "currentNone") {
        xywav_ck.checked = false
        xyrem_ck.checked = false
        other_ck.checked = false
      }

      if (e.target.id === "currentXywav" || e.target.id === "currentXyrem") {
        none_ck.checked = false
      }

      if (!xywav_ck.checked && !xyrem_ck.checked) {
        fields["currentMeds"] = ""
      }
      if (!none_ck.checked && !other_ck.checked) {
        fields["currentMedsB"] = ""
      }

      how_long_xywav.classList.remove("active")
      mentor_program_xywav.classList.remove("active")
      how_long_xyrem.classList.remove("active")

      if (xywav_ck.checked) {
        how_long_xywav.classList.add("active")
        mentor_program_xywav.classList.add("active")
      }
      if (xyrem_ck.checked) {
        how_long_xyrem.classList.add("active")
      }
    }

    setFields(fields)
  }

  const stayConnectedSend = async (event) => {
    event.preventDefault()
    const form = event.target

    const formValid = FormValidation(form, getFields)

    const medicationData = []

    if (getFields["currentMeds"] && getFields["currentMedsB"]) {
      medicationData.push(getFields["currentMeds"])
      medicationData.push(getFields["currentMedsB"])
    } else if (!getFields["currentMeds"] && getFields["currentMedsB"]) {
      medicationData.push(getFields["currentMedsB"])
    } else {
      medicationData.push(getFields["currentMeds"])
    }

    if (formValid) {
      try {
        const recaptchaToken = await executeRecaptcha("stayConnectedLoad")
        const registerData = {
          recaptcha: recaptchaToken || "qa-prefix",
          firstName: getFields["firstname"],
          lastName: getFields["lastname"],
          email: getFields["email"],
          zipCode: getFields["zip"],
          phoneNumber: getFields["phone"],
          optInEmail: "Yes",
          patientTypeQuestion: getFields["patientOrCaregiver"],
          narcType: getFields["typeOfNarcolepsy"],
          medication: medicationData,
          dataSource: "XYREM-STAYCONNECTED-2",
        }

        if (getFields["currentMeds"] === "XYREM" && getFields["howLongXyrem"]) {
          registerData["xyremDuration"] = getFields["howLongXyrem"]
        }
        if (getFields["currentMeds"] === "XYWAV" && getFields["howLongXywav"]) {
          registerData["xywavDuration"] = getFields["howLongXywav"]
        }
        if (
          getFields["currentMeds"] === "XYWAV" &&
          getFields["interestedXywavMentor"]
        ) {
          registerData["mentor"] = getFields["interestedXywavMentor"]
        }

        const success = (response) => {
          if (response.success) {
            navigate("/thank-you-for-signing-up")
          } else {
            console.log("error", response)
          }
        }
        const fail = (error) => {
          console.log("error", error)
        }
        Api.post_register(success, fail, registerData)
      } catch (error) {
        setBtnDisabled(true)
      }
    }
  }

  return (
    <form
      className="col-xs-12 stay-connected-form"
      method="post"
      id="stayConnected"
      onSubmit={stayConnectedSend}
    >
      <div className="col-xs-12">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label htmlFor="firstname">
              <span className="required">First Name*</span>
            </label>
            <input
              id="firstname"
              type="text"
              name="firstname"
              maxLength={50}
              onChange={handleChange}
            />
            <span className="form-error"></span>
          </div>
          <div className="col-xs-12 col-sm-6 input-padding-mobile">
            <label htmlFor="lastname">
              <span className="required">Last Name*</span>
            </label>
            <input
              id="lastname"
              type="text"
              name="lastname"
              maxLength={50}
              onChange={handleChange}
            />
            <span className="form-error"></span>
          </div>
        </div>
      </div>

      <div className="col-xs-12">
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <label htmlFor="email">
              <span className="required">Email Address*</span>
            </label>
            <input
              id="email"
              type="text"
              name="email"
              maxLength={50}
              onChange={handleChange}
            />
            <span className="form-error"></span>
          </div>
          <div className="col-xs-12 col-sm-4">
            <label htmlFor="zip">
              <span className="required">ZIP Code*</span>
            </label>
            <input
              id="zip"
              type="number"
              ref={zipRef}
              name="zip"
              maxLength={5}
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength)
              }}
              onKeyDown={handleOnKeyDown}
              onChange={handleChange}
            />
            <span className="form-error"></span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 receive-info">
            <span className="text">
              To receive information via text, please enter your mobile number
              (optional).
            </span>
            <br />
            <label htmlFor="phone">Phone Number</label>
            <input
              id="phone"
              name="phone"
              type="number"
              maxLength={10}
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength)
              }}
              onKeyDown={handleOnKeyDown}
              onChange={handleChange}
            />
            <span className="form-error"></span>
            <br />
            <span className="messaging">
              Messaging and data rates may apply. Reply STOP to 69389 to cancel,
              HELP for help. Up to 10 msg/mo.&nbsp;
              <span
                id="sms-terms-and-conditions"
                onChange="ga('send', 'event', 'stay connected', 'on click', 'T&C page - stay connected')"
              >
                <a className="blueLink" href="/sms-terms-and-conditions/">
                  SMS Terms &amp; Conditions
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div id="about-your-self" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12 flexy-radio-form-text">
            <p>
              To help us provide messages that are most relevant to you, please
              tell us a little more about yourself.
            </p>
          </div>
          <div className="col-xs-12 flexy-radio-form">
            <label>I am a *</label>
            <div className="flexy-radio-option">
              <label>
                <input
                  type="radio"
                  name="patientOrCaregiver"
                  value="Patient"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Patient</span>
              </label>
            </div>
            <div className="flexy-radio-option">
              <label>
                <input
                  type="radio"
                  name="patientOrCaregiver"
                  value="Caregiver"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Caregiver</span>
              </label>
            </div>
          </div>
          <div>
            <span className="form-error"></span>
          </div>
        </div>
      </div>

      <div id="narcolepsy-symptoms" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <div className="current-meds-text">
              <span>
                Which of the following medications are you taking for narcolepsy
                symptoms?*
              </span>
            </div>
            <div className="current-meds-form">
              <div className="current-meds-form-row create-checkbox">
                <input
                  type="checkbox"
                  id="currentXywav"
                  name="currentMeds"
                  value="XYWAV"
                  onChange={handleChange}
                />
                <label className="checkboxLabel waiver-checkbox"></label>
                <span>
                  XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and
                  sodium oxybates){" "}
                  <span className="no-break">oral solution</span>
                </span>
              </div>
              <div className="current-meds-form-row create-checkbox">
                <input
                  type="checkbox"
                  id="currentXyrem"
                  name="currentMeds"
                  value="XYREM"
                  onChange={handleChange}
                />
                <label className="checkboxLabel waiver-checkbox"></label>
                <span>XYREM</span>
              </div>
              <div className="current-meds-form-row create-checkbox">
                <input
                  type="checkbox"
                  id="currentOther"
                  name="currentMedsB"
                  value="Other"
                  onChange={handleChange}
                />
                <label className="checkboxLabel waiver-checkbox"></label>
                <span>Other</span>
              </div>
              <div className="current-meds-form-row create-checkbox">
                <input
                  type="checkbox"
                  id="currentNone"
                  name="currentMedsB"
                  value="None"
                  onChange={handleChange}
                />
                <label className="checkboxLabel waiver-checkbox"></label>
                <span>None</span>
              </div>
            </div>
            <div>
              <span className="form-error"></span>
            </div>
          </div>
        </div>
      </div>

      <div id="how-long-xywav-section" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <div className="flexy-radio-form-text">
              <span>
                How long have you or the person for whom you are a caregiver
                been taking XYWAV?
              </span>
            </div>
            <div className="col-xs-12 flexy-radio-form">
              <div className="flexy-radio-option no-margin">
                <label>
                  <input
                    type="radio"
                    name="howLongXywav"
                    value="0 to 6 months"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>0 to 6 months</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXywav"
                    value="6 months to 1 year"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>6 months to 1 year</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXywav"
                    value="1 year to 3 years"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>1 year to 3 years</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXywav"
                    value="3 years or more"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>3 years or more</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="xywav-mentor-section" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <div className="flexy-radio-form-text">
              <span>
                Are you interested in learning about the XYWAV Mentor Program?
              </span>
            </div>
          </div>
          <div className="col-xs-12 flexy-radio-form">
            <div className="flexy-radio-option no-margin">
              <label>
                <input
                  type="radio"
                  name="interestedXywavMentor"
                  value="Yes"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Yes</span>
              </label>
            </div>
            <div className="flexy-radio-option">
              <label>
                <input
                  type="radio"
                  name="interestedXywavMentor"
                  value="No"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>No</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div id="how-long-xyrem-section" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <div className="flexy-radio-form-text">
              <span>
                How long have you or the person for whom you are a caregiver
                been taking XYREM?
              </span>
            </div>
            <div className="col-xs-12 flexy-radio-form">
              <div className="flexy-radio-option no-margin">
                <label>
                  <input
                    type="radio"
                    name="howLongXyrem"
                    value="0 to 6 months"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>0 to 6 months</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXyrem"
                    value="6 months to 1 year"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>6 months to 1 year</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXyrem"
                    value="1 year to 3 years"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>1 year to 3 years</span>
                </label>
              </div>
              <div className="flexy-radio-option">
                <label>
                  <input
                    type="radio"
                    name="howLongXyrem"
                    value="3 years or more"
                    onChange={handleChange}
                  />
                  <div className="custom-radio"></div>
                  <span>3 years or more</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="narcolepsy-diagnosed" className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <div className="flexy-radio-form-text">
              <span>What type of narcolepsy are you diagnosed with?*</span>
            </div>
          </div>
          <div className="col-xs-12 flexy-radio-form">
            <div className="flexy-radio-option no-margin">
              <label>
                <input
                  type="radio"
                  name="typeOfNarcolepsy"
                  value="Narcolepsy with cataplexy (type 1)"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Narcolepsy with cataplexy (type 1)</span>
              </label>
            </div>
            <div className="flexy-radio-option">
              <label>
                <input
                  type="radio"
                  name="typeOfNarcolepsy"
                  value="Narcolepsy without cataplexy (type 2)"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Narcolepsy without cataplexy (type 2)</span>
              </label>
            </div>
            <div className="flexy-radio-option">
              <label>
                <input
                  type="radio"
                  name="typeOfNarcolepsy"
                  value="Uncertain"
                  onChange={handleChange}
                />
                <div className="custom-radio"></div>
                <span>Uncertain</span>
              </label>
            </div>
          </div>
          <div>
            <span className="form-error"></span>
          </div>
        </div>
      </div>

      <div id="disclaimer" className="col-xs-12">
        <div className="col-xs-12">
          <div className="privacy-agree-wrapper col-xs-12 col-sm-6 col-md-7">
            <div className="create-checkbox">
              <input
                type="checkbox"
                id="takingXyrem"
                name="confirmAge"
                value="true"
                onChange={handleChange}
              />
              <label
                for="takingXyrem"
                className="checkboxLabel waiver-checkbox"
              ></label>
            </div>
            <div className="disclaimer-wrapper">
              <p className="disclaimer-text">
                By checking this box, you confirm that you are 18 years of age
                or older and a resident of the US. You are indicating that you
                would like to receive information from Jazz Pharmaceuticals
                about educational programs, products, and services. You can
                unsubscribe at any time from future communications from Jazz
                Pharmaceuticals by clicking the “unsubscribe” link provided in
                communications from Jazz Pharmaceuticals.
              </p>
              <p className="disclaimer-text">
                By submitting this form, you consent to the collection, use and
                sharing of sensitive information you provide through this form,
                including health-related information, by Jazz Pharmaceuticals,
                its affiliates and service providers to conduct marketing
                activities and to communicate with you regarding products and
                services that may be of interest to you. You understand that
                Jazz Pharmaceuticals will not sell your sensitive information to
                third parties.
              </p>
              <p className="disclaimer-text">
                You can withdraw consent from collection, use or sharing of your
                sensitive information at any time using one of the methods
                listed in the{" "}
                <a
                  href="https://www.jazzpharma.com/us-consumer-health-data-privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-open-modal={true}
                >
                  US Consumer Health Data Privacy Policy
                </a>
                . By clicking Submit, you confirm you have read and agreed with{" "}
                <a
                  href="https://www.jazzpharma.com/privacy-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-open-modal={true}
                >
                  Jazz Pharmaceuticals' Privacy Statement
                </a>{" "}
                and{" "}
                <a
                  href="https://www.jazzpharma.com/us-consumer-health-data-privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-open-modal={true}
                >
                  US Consumer Health Data Privacy Policy
                </a>
                .
              </p>
              <span className="form-error"></span>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <input
            id="stayConnectedSubmit"
            type="submit"
            action=""
            value="SIGN UP"
            className="button button--orange"
            disabled={getBtnDisabled}
          />
        </div>
      </div>
    </form>
  )
}

export default Form
