import React from "react"
import Layout from "@components/structure/Layout.jsx"
import Seo from "@components/utility/Seo.jsx"
import StayConnectedForm from "@components/StayConnectedForm.jsx"

const StayConnected = () => {
  return (
    <Layout pageClass="stay-connected">
      <Seo
        canonical="/stay-connected"
        title="Stay Connected | XYREM®"
        description="Sign up to receive updates about XYREM® (sodium oxybate). See XYREM® Prescribing Information & BOXED Warning about serious side effects, CNS depression, and abuse and misuse."
      />

      <section id="stay-connected-wrapper" className="section section--first">
        <div className="container-md">
          <h1 className="blueHeading text-center">Want to stay in the loop?</h1>
          <h4 className="stay-connected-sub-header">
            Please provide your name and email to receive information about
            XYREM.
          </h4>
          <div className="col-xs-12 required-field">*Required field</div>
          <StayConnectedForm />
        </div>
      </section>
    </Layout>
  )
}

export default StayConnected
