

const API_URL =
    typeof window !== "undefined" &&
    /^(www\.)?xyrem.com|us13\.prod\.acquia-sites\.com$/.test(
    window.location.hostname
    )
    ? "https://jazz-api.klickcloud.net/api/register"
    : "https://jazz-api-stage.klickcloud.net/api/register"

export const Api = {
    post_register(success, fail, data) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                return success(result)
            })
            .catch(error => {
                fail(error);
            })
    }
}