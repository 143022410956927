import React from "react"

export const FormValidation = (form, fields) => {

    if(!form) {
        return false;
    }

    const emailPattern = /^(?!.{51})[0-9a-zA-Z]+([0-9a-zA-Z]*[.!#$%&''*+/=?^_`{|}-])*[0-9a-zA-Z]*@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,50}$/
    const zipCodeRegex = /^[0-9]{5}$/
    const phoneRegex = /^(|[0-9]{10})$/


    const errorMsg = {
        required: '*Required',
        invalidEmail: 'Please enter a valid email address',
        invalidZip: 'Please enter a valid zip code',
        invalidPhone: 'Please enter a valid phone number',
        empty: ''
    }

    const isValidEmail = (email) => new RegExp(emailPattern).test(email);
    const isValidZip = (zip) => new RegExp(zipCodeRegex).test(zip);
    const isValidPhone = (phone) => new RegExp(phoneRegex).test(phone);

    let formValid = true;

    
    const inputs = form.querySelectorAll('input');
    let about_your_self_error = null;
    let narcolepsy_diagnosed_error = null;
    let narcolepsy_symptoms_error = null;
    const disclaimer_error = document.getElementById('disclaimer') ? document.getElementById('disclaimer').querySelector('.form-error') : null;
    

    if(form.id === 'stayConnected') {
        about_your_self_error = document.getElementById('about-your-self').querySelector('.form-error');
        narcolepsy_diagnosed_error = document.getElementById('narcolepsy-diagnosed').querySelector('.form-error');
        narcolepsy_symptoms_error = document.getElementById('narcolepsy-symptoms').querySelector('.form-error');
        about_your_self_error.innerHTML = errorMsg.empty;
        narcolepsy_diagnosed_error.innerHTML = errorMsg.empty;
        narcolepsy_symptoms_error.innerHTML = errorMsg.empty;
    }

    if(disclaimer_error) {
        disclaimer_error.innerHTML = errorMsg.empty;
    }

    inputs.forEach(function (element) {
        const type = element.type;
        const elementName = element.name;


        if(type === 'text' || type === 'number') {
            const errorField = element.parentElement.querySelector('.form-error')

            if(errorField) {
                errorField.innerHTML = errorMsg.empty;
                element.classList.remove('input-error');
            }
            
            if(elementName !== 'phone' && !fields[elementName] && element.parentElement.querySelector('.required')) {
                formValid = false;
                errorField.innerHTML = errorMsg.required;
                element.classList.add('input-error');
            }
            else if(elementName === 'email' && !isValidEmail(fields[elementName])) {
                formValid = false;
                errorField.innerHTML = errorMsg.invalidEmail;
                element.classList.add('input-error');
            }
            else if(elementName === 'zip' && !isValidZip(fields[elementName])) {
                formValid = false;
                errorField.innerHTML = errorMsg.invalidZip;
                element.classList.add('input-error');
            }
            else if(elementName === 'zip' && !isValidZip(fields[elementName])) {
                formValid = false;
                errorField.innerHTML = errorMsg.invalidZip;
                element.classList.add('input-error');
            }
            else if(elementName === 'phone' && !isValidPhone(fields[elementName])) {
                if(fields[elementName] !== undefined) {
                    formValid = false;
                    errorField.innerHTML = errorMsg.invalidPhone;
                    element.classList.add('input-error');
                }
            }
        }

        if(type === 'radio') {
            if(elementName === 'patientOrCaregiver' && !fields[elementName]) {
                about_your_self_error.innerHTML = errorMsg.required;
                formValid = false;
            }
            if(elementName === 'typeOfNarcolepsy' && !fields[elementName]) {
                narcolepsy_diagnosed_error.innerHTML = errorMsg.required;
                formValid = false;
            }
            
        }

        if(type === 'checkbox') {
            if((elementName === 'currentMeds' || elementName === 'currentMedsB') && !fields['currentMeds'] && !fields['currentMedsB']) {
                narcolepsy_symptoms_error.innerHTML = errorMsg.required;
                formValid = false;
            }
            if(elementName === 'confirmAge' && !fields[elementName] && disclaimer_error) {
                disclaimer_error.innerHTML = errorMsg.required;
                formValid = false;
            }
        }
    });

    if(!formValid) {
        window.scrollTo(0, 0);
    }

    return formValid;
}

export default FormValidation
